import React, { useState, useEffect, memo } from "react";
import { Spinner, Modal, Form, Col } from "react-bootstrap";
import { Tooltip } from "antd";
import { Button } from "react-bootstrap";
import axiosInstance from "../../axios/axios";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import { DatePicker, Select } from "antd";
import moment from "moment";
import { BsExclamationCircle } from "react-icons/bs";
import { SpinnerDiv } from "../../StyledComponents";
import { Div, Span } from "../billing/CardTransactions/styles";
import { BsFillCaretDownFill } from "react-icons/bs";
import DropdownButton from "react-bootstrap/DropdownButton";
import { Dropdown as Dropdownant } from "react-bootstrap";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import { useSelector, useDispatch } from "react-redux";
import { SetPatientBool } from "./../../StateManagement/Reducers/ScheduleState";
import {
  RepeatBtn,
  RepeatBtnConfirm,
  RepeatFooter,
  RepeatPopupBlock,
  RepeatPopupBodyBtn,
  RepeatPopupBodyText,
  RepeatPopupHeader,
} from "../schedule/styles";
import { Decrypt_Value, Encrypt_Value } from "../../MiddleWare/EncryptDecrypt";

const AddAppTemplate = ({
  clientShow,
  dataFetch,
  id,
  row,
  handleAlert,
  setEid,
  setRedirectTo,
  show,
  from = "unknown",
  where = "unknown",
}) => {
  if (id === 0) {
    row.pc_startTime = "";
    row.pc_catid = "";
    row.pc_facility = "";
    row.pc_aid = "";
    row.pc_duration = "";
    row.pc_eventDate = "";
    row.pc_hometext = "";
  }

  //-------------------------------------------State and Variables Start-------------------------------------------------------- //
  const isLoggedIn = id;
  const { Option } = Select;
  const [ufc] = useState(
    Decrypt_Value(localStorage.getItem("facility_id"), "vozo")
  );
  const [main_facility] = useState(
    Decrypt_Value(localStorage.getItem("main_facility"), "vozo")
  );
  const [WeeklyDisable, setWeeklyDisable] = useState("");
  const [app_off, setappoint_office] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [AlertDeleteMsg, setAlertDeleteMsg] = useState("");
  const dtFormat = localStorage.getItem("dateFormat");
  const [isStateUpdated, setIsStateUpdated] = useState(false);
  const [apptdate, setapptdate] = useState(moment(new Date()).format(dtFormat));
  const [providerid, setproviderid] = useState("");
  const [untildate, setuntildate] = useState(""); //Future
  const [office, setoffice] = useState([]);
  const [sourceprovider, setSourceprovider] = useState([]);
  const [weekday, setweekday] = useState("");
  const [dayswise, setdayswise] = useState(false);
  const [checking1, setchecking1] = useState(false); //Future
  const [dayswise_c, setdayswise_c] = useState(false);
  const [SpinnerVal, setSpinnerVal] = useState(true);
  const [per, setper] = useState(false);
  const [oer, setoer] = useState(false);
  const [cer, setcer] = useState(false);
  const [der, setder] = useState(false);
  const [ter, setter] = useState(false);
  // const [dur, setdur] = useState(false);
  const [ModalAlerShow, setModalAlerShow] = useState(false);
  const [ModalAlerShow1, setModalAlerShow1] = useState(false);
  const [ModalMsg, setModalMsg] = useState("");
  const [ter1, setter1] = useState(false);
  const [sourceprofile, setSourceprofile] = useState([]);
  const [btnDisable, setBtnDis] = useState(false);
  const [repeatOpenPopup, setRepeatOpenPopup] = useState(false);
  const [futureBtnBlock, setFutureBtnBlock] = useState(false);

  var storedNames = Decrypt_Value(localStorage.getItem("setroles"), "vozo");
  var userID = Decrypt_Value(localStorage.getItem("user_id"), "vozo");
  var authorized = Decrypt_Value(localStorage.getItem("authorized"), "vozo");
  var main_pro = Decrypt_Value(localStorage.getItem("main_pro"), "vozo");
  var owner_id = Decrypt_Value(localStorage.getItem("owner_id"), "vozo");
  var group_id = Decrypt_Value(localStorage.getItem("group_id"), "vozo");
  const [office_1, setoffice_1] = useState(
    authorized === "1" ? ufc : String(main_facility)
  );

  const [repvalue, setrepValue] = useState("Does not repeat");
  const [repeatselectnew, setrepeatselectnew] = useState("");
  const [repeatselect2new, setrepeatselect2new] = useState("");
  const [day, setDay] = useState("");
  const [selectdayError, setselectdayError] = useState(false);
  const [custChk, setcustChk] = useState("");
  const [dyOfMont, setDyOfMont] = useState("");
  const [wkOfMont, setWkOfMont] = useState("");
  const [repeatselectmonth, setRepeatSelectMonth] = useState("");
  const [Week_and_every, setWeek_and_every] = useState(false);
  const [Month_and_every, setMonth_and_every] = useState(false);
  const [month, setMonth] = useState("");
  const [apptEndDate, setApptEndDate] = useState(
    moment(new Date()).format(dtFormat)
  );
  const [untilErr, setUntilErr] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [antDropdown, setAntDropdown] = useState("");
  const [frtimeSlots, setFromTimeSlots] = useState([]);
  const [totimeSlots, setToTimeSlots] = useState([]);
  const [timeDifference, setTimeDifference] = useState("");
  const [minuteDifference, setMinuteDifference] = useState("");
  const [comments, setComments] = useState("");
  const bill_facility_id = useSelector(
    (state) => state.Schedule.BillingFacility
  );
  const defaultRepeatBtn = {
    current: false,
    future: false,
    all: false,
  };
  const [repeatbtn, setrepeatbtn] = useState(defaultRepeatBtn);
  const [shouldCallDatas2, setShouldCallDatas2] = useState(false);

  const [inTime, setInTime] = useState("");
  const [outTime, setOutTime] = useState("");
  const [fromShow, setFromShow] = useState(false);
  const [toShow, setToShow] = useState(false);
  const [tempOpen, setTempOpen] = useState(false);
  const [ConfirmOpen, setConfirmOpen] = useState(false);

  var regexFirstNum = /^[a-zA-Z].*/;
  const dispatch = useDispatch();
  //-------------------------------------------UseState End-------------------------------------------------------- //

  //-------------------------------------------Function Start-------------------------------------------------------- //

  const buttonDetailClickActions = async (e) => {
    dispatch(SetPatientBool(false));
    if (e !== "noneed") {
      e.preventDefault();
    }
    if (bill_facility_id === undefined) {
      setRedirectTo(true);
      return false;
    }

    if (ter1 || ter) {
      setBtnDis(false);

      return false;
    }
    var err = 0;
    if (inTime === "") {
      setter(true);
      err = 2;
    }

    if (outTime === "") {
      setter1(true);
      err = 2;
    }

    if (providerid == "") {
      setper(true);
      err = 2;
    }
    if (office_1 == "") {
      setoer(true);
      err = 3;
    }
    if (app_off == "") {
      setcer(true);
      err = 2;
    }

    if (
      repvalue !== "" &&
      repvalue !== undefined &&
      repvalue !== null &&
      repvalue !== "Does not repeat" &&
      apptEndDate === ""
    ) {
      setUntilErr(true);
      err = 2;
    }
    if (apptdate == "") {
      setder(true);
      err = 2;
    }

    if (inTime === outTime && inTime && outTime !== "12:00 AM") {
      setter1(true);
      err = 2;
    }

    if (err > 0) {
      setBtnDis(false);

      return false;
    }

    if (row.recType !== 0 && row.recType !== "0" && row.recType !== undefined) {
      const currdate = moment().format("YYYY-MM-DD");
      const futureDates =
        row.futureDatesall && row.futureDatesall.length > 0
          ? row.futureDatesall.filter((date) => moment(date).isAfter(currdate))
          : [];

      if (futureDates.length < 1) {
        setFutureBtnBlock(true);
      } else {
        setFutureBtnBlock(false);
      }
      if (row.pc_catid === "4" && app_off === "5") {
        setAlertDeleteMsg(
          "Your Future Telehealth Appointments(Except Meet Completed Appointment's)  are Deleted Because You choose Unavailable from telehealth"
        );
      } else {
        setAlertDeleteMsg("");
      }
      setRepeatOpenPopup(true);
      return;
    } else {
      saveRepeatAvail();
    }
  };

  const forcurrentonly = () => {
    setIsStateUpdated(false);
    setchecking1(false);
    setdayswise(false);
    setMonth_and_every(false);
    setrepeatselectnew("");
    setrepeatselect2new("");
    setRepeatSelectMonth("");

    setweekday("");
    setcustChk("");
    setIsStateUpdated(true);
    setrepValue("Does not repeat");
  };

  const saveRepeatAvail = async () => {
    let MonthOccurences;
    //for current only
    if (
      row.recType !== 0 &&
      row.recType !== "0" &&
      repeatbtn.all === false &&
      repeatbtn.future === false
    ) {
      forcurrentonly();
    }

    //for current only
    var startTime_format = moment(inTime, "hh:mm:ss a");
    var endTime_format = moment(outTime, "hh:mm:ss a");
    if (endTime_format.isSameOrBefore(startTime_format)) {
      endTime_format.add(1, "day");
    }
    var seconds = endTime_format.diff(startTime_format, "seconds");
    var select1, select2, untildate, days_wise, repeats;

    if (weekday != null) {
      if (weekday) {
        repeats = weekday;
      } else {
        repeats = "";
      }
    } else {
      repeats = "";
    }

    if (repeatselectnew) {
      select1 = repeatselectnew;
    } else {
      select1 = 0;
    }

    if (repeatselect2new) {
      select2 = repeatselect2new;
    } else {
      select2 = "";
    }

    if (dayswise != null) {
      if (dayswise === true) {
        days_wise = true;
      } else {
        days_wise = false;
      }
    } else {
      days_wise = false;
    }

    var currentDate = new Date();

    var untildate;
    var repeat_type;

    untildate = "";
    repeat_type = "norepeat";

    let inserttype = "Does not repeat";
    inserttype = "weekly";

    let recurrType;
    let endlastdateedit;
    if (
      repvalue !== "" &&
      repvalue !== undefined &&
      repvalue !== null &&
      repvalue !== "Does not repeat" &&
      repvalue !== "Does not repeat "
    ) {
      recurrType = 1;
    } else {
      recurrType = 0;
    }
    if (
      repvalue === "" ||
      repvalue === undefined ||
      repvalue === null ||
      repvalue === "Does not repeat"
    ) {
      endlastdateedit = apptdate;
    } else {
      endlastdateedit = apptEndDate;
    }

    let data = {
      pc_catid: app_off,
      pc_aid: providerid,
      pc_pid: "",
      pc_duration: seconds,
      pc_apptstatus: "0",
      pc_eventDate: moment(apptdate, dtFormat).format("YYYY-MM-DD"),

      futureCheck: repeatbtn.future,
      all: repeatbtn.all,
      pc_endDate: moment(endlastdateedit, dtFormat).format("YYYY-MM-DD"),
      pc_startTime: inTime,
      pc_endTime: outTime,
      pc_facility: office_1,
      pc_title: categoryName,
      pc_hometext: comments,
      pc_informant: userID,
      pc_billing_location: "10",
      pc_select1: select1,
      pc_select2: select2,

      pc_untildate:
        untildate !== ""
          ? moment(untildate).format("YYYY-MM-DD")
          : moment(apptEndDate, dtFormat).format("YYYY-MM-DD"),
      pc_lastdate:
        apptEndDate !== ""
          ? moment(apptEndDate, dtFormat).format("Y-MM-DD")
          : moment(untildate, dtFormat).format("Y-MM-DD"),
      days_wise: days_wise,
      repeats: repeats,
      encounter: id,
      slotDate: moment(apptdate, dtFormat).format("Y-MM-DD"),
      slotDate1: moment(row.futureDates).format("Y-MM-DD"),
      allRepeatDates: row.repDates,
      futureDates: row.futureDatesall,
      insertType: inserttype,
      recurrance: row.recType,
      pc_fromdate: moment(currentDate, dtFormat).format("YYYY-MM-DD"),
      appttype: "1",
      walkin: false,
      pc_todate: moment(currentDate, dtFormat)
        .add(1, "days")
        .format("YYYY-MM-DD"),
      examRoom: "0",
      pc_repeat_type: repeat_type,
      week_and_every_day: Week_and_every,
      month_and_every_day: Month_and_every,

      on_never_after: custChk,
      repeat_select: repvalue,
      pc_repeatselectmonth: repeatselectmonth,
      selectMonthAlp:
        repeatselectmonth === 1 || repeatselectmonth === "1"
          ? `Monthly on day ${dyOfMont}`
          : `Monthly on the ${wkOfMont} ${day}`,
      repeatSelectMonthOccurenceDates: MonthOccurences,
    };

    let submit_enc = Encrypt_Value(data, "vozo");
    if (
      repeatbtn.future === false &&
      repeatbtn.all === false &&
      repeatbtn.current === false &&
      isStateUpdated === false
    ) {
      axiosInstance
        .put("/vozo/Appointmenttemplate/update?id=" + id, submit_enc)
        .then((res) => {
          let response = Decrypt_Value(res.data, "vozo");

          if (response === "success" || response === "1" || response === 1) {
            setEid();
            setModalMsg("Availability Updated Successfully..!");
            setModalAlerShow1(true);
            setBtnDis(true);

            setTimeout(() => {
              setModalAlerShow1(false);
              setRepeatOpenPopup(false);
              clientShow();
              dataFetch();
            }, 1500);
          } else if (response === "repeat in future") {
            handleAlert();
          } else {
            setModalMsg(response);
            setModalAlerShow(true);
            setTimeout(() => {
              setModalAlerShow(false);
            }, 2000);
          }
        });
    } else if (
      (row.recType !== 0 &&
        row.recType !== "0" &&
        repeatbtn.future !== false) ||
      (repeatbtn.all !== false && !isStateUpdated)
    ) {
      axiosInstance
        .put("/vozo/Appointmenttemplate/update?id=" + id, submit_enc)
        .then((res) => {
          let response = Decrypt_Value(res.data, "vozo");

          if (response === "success" || response === "1" || response === 1) {
            setEid();
            setModalMsg("Availability Updated Successfully..!");
            setModalAlerShow1(true);
            setBtnDis(true);

            setTimeout(() => {
              setModalAlerShow1(false);
              setRepeatOpenPopup(false);
              clientShow();
              dataFetch();
            }, 1500);
          } else if (response === "repeat in future") {
            handleAlert();
          } else {
            setModalMsg(response);
            setModalAlerShow(true);
            setTimeout(() => {
              setModalAlerShow(false);
            }, 2000);
          }
        });
    } else if (
      isStateUpdated &&
      row.recType !== 0 &&
      row.recType !== "0" &&
      repeatbtn.future === false &&
      repeatbtn.all === false
    ) {
      setIsStateUpdated(false);
      axiosInstance
        .put("/vozo/Appointmenttemplate/update?id=" + id, submit_enc)
        .then((res) => {
          let response = Decrypt_Value(res.data, "vozo");

          if (response === "success" || response === "1" || response === 1) {
            setEid();
            setModalMsg("Availability Updated Successfully..!");
            setModalAlerShow1(true);
            setBtnDis(true);

            setTimeout(() => {
              setModalAlerShow1(false);
              setRepeatOpenPopup(false);
              clientShow();
              dataFetch();
            }, 1500);
          } else if (response === "repeat in future") {
            handleAlert();
          } else {
            setModalMsg(response);
            setModalAlerShow(true);
            setTimeout(() => {
              setModalAlerShow(false);
            }, 2000);
          }
        });
    }
  };

  const handlerepSelect = (e) => {
    if (e === "Does not repeat") {
      setchecking1(false);
      setdayswise(false);
      setMonth_and_every(false);
      setrepeatselectnew("");
      setrepeatselect2new("");
      setRepeatSelectMonth("");
      setweekday("");
      setcustChk("");
      setselectdayError(false);
    } else if (e === "Daily") {
      setchecking1(true);
      setrepeatselectnew("1");
      setrepeatselect2new("0");
      setdayswise(false);
      setMonth_and_every(false);
      setselectdayError(false);
    } else if (e.includes("Week")) {
      setchecking1(true);
      setdayswise(true);
      setMonth_and_every(false);
    }

    setrepValue(e);
  };

  const WeekdayChange = (val) => {
    const hasOnlyEmptyStrings =
      val.length > 0 && val.every((item) => item === "");
    if (hasOnlyEmptyStrings) {
      setweekday("");
    } else {
      setweekday(val);
    }
    setselectdayError(false);
  };

  const providerChange = (e, data) => {
    setproviderid(data.value);
    setper(false);
  };

  const days_check = () => {
    setdayswise(!dayswise);
    setdayswise_c(!dayswise_c);
    setweekday("");
  };

  const OnSubmit = (e) => {
    if (e !== "none") {
      e.preventDefault();
    }

    if (bill_facility_id === undefined) {
      setRedirectTo(true);
      return false;
    }

    if (ter1 || ter) {
      setBtnDis(false);
      return false;
    }
    var err = 0;

    if (repvalue === "Week" && (weekday === "" || weekday.length == 0)) {
      setselectdayError(true);
      err = 2;
    }
    if (inTime === "") {
      setter(true);
      err = 2;
    }

    if (outTime === "") {
      setter1(true);
      err = 2;
    }

    if (providerid == "") {
      setper(true);
      err = 2;
    }
    if (office_1 == "") {
      setoer(true);
      err = 3;
    }
    if (app_off == "") {
      setcer(true);
      err = 2;
    }

    if (
      repvalue !== "" &&
      repvalue !== undefined &&
      repvalue !== null &&
      repvalue !== "Does not repeat" &&
      apptEndDate === ""
    ) {
      setUntilErr(true);
      err = 2;
    }

    if (apptdate == "") {
      setder(true);
      err = 2;
    }

    if (inTime === outTime && inTime && outTime !== "12:00 AM") {
      setter1(true);
      err = 2;
    }

    if (err > 0) {
      setBtnDis(false);

      return false;
    }
    if (app_off != "") {
      setConfirmOpen(true);
    }
  };

  const CheckOnSubmit = async (e) => {
    setConfirmOpen(false);
    let endlastdateedit;
    let MonthOccurences;

    if (
      repvalue === "" ||
      repvalue === undefined ||
      repvalue === null ||
      repvalue === "Does not repeat"
    ) {
      endlastdateedit = apptdate;
    } else {
      endlastdateedit = apptEndDate;
    }
    var startTime_format = moment(inTime, "hh:mm:ss a");
    var endTime_format = moment(outTime, "hh:mm:ss a");
    if (endTime_format.isSameOrBefore(startTime_format)) {
      endTime_format.add(1, "day");
    }
    var seconds = endTime_format.diff(startTime_format, "seconds");
    var select1, select2, untildate, days_wise, repeats;
    if (id == 0) {
      if (weekday != null) {
        if (weekday) {
          repeats = weekday;
        } else {
          repeats = "";
        }
      } else {
        repeats = "";
      }

      if (repeatselectnew) {
        select1 = repeatselectnew;
      } else {
        select1 = 0;
      }

      if (repeatselect2new) {
        select2 = repeatselect2new;
      } else {
        select2 = "";
      }

      if (dayswise != null) {
        if (dayswise === true) {
          days_wise = true;
        } else {
          days_wise = false;
        }
      } else {
        days_wise = false;
      }

      var currentDate = new Date();
      var untildate;
      var repeat_type;
      untildate = "";
      repeat_type = "norepeat";

      // const repeatsString =
      //   repeats &&
      //   repeats
      //     .filter((value) => {
      //       if (typeof value === "number") {
      //         return !isNaN(value);
      //       }
      //       if (typeof value === "string") {
      //         const num = Number(value.trim());
      //         return !isNaN(num) && num !== "";
      //       }
      //       return false;
      //     })
      //     .map((value) =>
      //       typeof value === "string" ? Number(value.trim()) : value
      //     )
      //     .filter((num) => num !== 0)
      //     .sort((a, b) => a - b)
      //     .join(",");
      // return;
      let data = {
        pc_catid: app_off,
        pc_aid: providerid,
        pc_pid: "",
        pc_duration: seconds,
        pc_apptstatus: "0",
        pc_eventDate: moment(apptdate, dtFormat).format("YYYY-MM-DD"),
        pc_endDate: moment(endlastdateedit, dtFormat).format("YYYY-MM-DD"),

        pc_startTime: inTime,
        pc_endTime: outTime,
        pc_facility: office_1,
        pc_title: categoryName,
        pc_hometext: comments,
        pc_informant: authorized === "1" ? userID : main_pro,
        pc_billing_location: "10",
        pc_select1: select1,
        pc_select2: select2,

        pc_untildate:
          untildate !== ""
            ? moment(untildate).format("YYYY-MM-DD")
            : moment(apptEndDate, dtFormat).format("YYYY-MM-DD"),

        days_wise: days_wise,
        // days_of_week: repeatsString,
        // allday: allday,
        repeats: repeats,
        pc_fromdate: moment(currentDate, dtFormat).format("YYYY-MM-DD"),
        appttype: "1",
        walkin: false,
        pc_todate: moment(currentDate, dtFormat).format("YYYY-MM-DD"),
        examRoom: "0",
        pc_repeat_type: repeat_type,
        week_and_every_day: Week_and_every,
        month_and_every_day: Month_and_every,

        on_never_after: custChk,
        repeat_select: repvalue,
        pc_repeatselectmonth: repeatselectmonth,
        selectMonthAlp:
          repeatselectmonth === 1 || repeatselectmonth === "1"
            ? `Monthly on day ${dyOfMont}`
            : `Monthly on the ${wkOfMont} ${day}`,
        repeatSelectMonthOccurenceDates: MonthOccurences,
      };

      let submit_enc = Encrypt_Value(data, "vozo");
      // return false;
      axiosInstance
        .post("/vozo/appointment/pro/avail", submit_enc)
        .then((res) => {
          // let response = Decrypt_Value(res.data, "vozo");
          let response = res.data;
          // return;
          if (response === "success") {
            setBtnDis(true);

            setModalMsg("Availability Added Successfully..!");
            setEid();
            setModalAlerShow1(true);
            // return;
            setTimeout(() => {
              setModalAlerShow1(false);
              clientShow();
              dataFetch();
            }, 1500);
          } else if (
            response === "Already Booked, Please Choose Another Slot"
          ) {
            setModalMsg(response);
            setModalAlerShow(true);

            setTimeout(() => {
              setModalAlerShow(false);
            }, 2000);
          } else if (response === "repeat in future") {
            handleAlert();
          } else {
            setModalMsg(response);
            setModalAlerShow(true);

            setTimeout(() => {
              setModalAlerShow(false);
            }, 3000);
          }
        });
    }
    //
    else {
      buttonDetailClickActions(e);
    }
  };

  const OfficeChange = (data) => {
    setappoint_office("");
    setCategoryName("");
    setproviderid("");
    setoffice_1(data.value);
    setoer(false);
    setShouldCallDatas2(true);
  };

  const CategoryChange = (data) => {
    setappoint_office(data.value);
    setCategoryName(data.title);
    setcer(false);
    setComments("");
  };

  const datas_1 = async () => {
    let Data_frsh_doc =
      "authorized=" +
      authorized +
      "&main_pro=" +
      main_pro +
      "&group_id=" +
      group_id;
    let Data_enc_doc = Encrypt_Value(Data_frsh_doc, "vozo");
    await axiosInstance
      .get("/vozonew/facility?search=" + Data_enc_doc)
      .then((response) => {
        let decryptval = Decrypt_Value(response.data, "vozo");
        setoffice(decryptval);
        setSpinnerVal(false);
      })
      .catch((err) => {
        // setoffice([]);
        setSpinnerVal(false);
      });

    // axiosInstance
    //   .get("/vozo/getapptemplate/appointmentoffice")
    //   .then((response) => {
    //     setappointmentoffice(response.data);
    //   });

    await axiosInstance
      .get("/vozo/category")
      .then((response) => {
        let temp_role_cat = Decrypt_Value(response.data, "vozo");
        setSourceprofile(temp_role_cat);
      })
      .catch((err) => {});
  };
  const datas_2 = async () => {
    let pro_data =
      "&pat_share=" +
      storedNames.pat_g +
      "&main_pro=" +
      main_pro +
      "&owner_id=" +
      owner_id +
      "&userID=" +
      userID +
      "&authorized=" +
      authorized +
      "&facilityId=" +
      office_1;
    let pro_data_enc = Encrypt_Value(pro_data, "vozo");
    await axiosInstance
      .get("/vozonew/provider?search=" + pro_data_enc)
      .then((response) => {
        let temp_role = Decrypt_Value(response.data, "vozo");
        setSourceprovider(temp_role);
      });
  };

  const handleFromTime = (e) => {
    if (fromShow === false) {
      setFromShow(true);
      setToShow(false);
      setTempOpen(true);
    } else {
      setFromShow(false);
    }
  };
  const handleToTime = () => {
    setter1(false);
    if (toShow === false) {
      setToShow(true);
      setFromShow(false);
      setTempOpen(true);
    } else {
      setToShow(false);
    }
  };

  const calculateTimeDifference = () => {
    if (inTime !== "" && outTime !== "") {
      const fromDateTime = new Date(`2000-01-01 ${inTime}`);
      let toDateTime = new Date(`2000-01-01 ${outTime}`);

      // Check if outTime is earlier than inTime, implying it's on the next day
      if (inTime === "12:00 AM" && outTime === "12:00 AM") {
        setTimeDifference(24);
        setMinuteDifference(0);
        return;
      }
      if (outTime === "12:00 AM" && inTime !== "12:00 AM") {
        toDateTime.setDate(toDateTime.getDate() + 1); // Move to the next day
      }
      if (toDateTime <= fromDateTime && outTime !== "12:00 AM") {
        setTimeDifference("");
        setMinuteDifference("");
        return;
      }

      const timeDifference = toDateTime - fromDateTime;

      const hours = Math.floor(timeDifference / (1000 * 60 * 60));
      const minutes = Math.floor(
        (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
      );
      setTimeDifference(hours);
      setMinuteDifference(minutes);
    }
  };

  const handleFromButton = (e, index) => {
    e.preventDefault();
    if (fromShow === true || toShow === true) {
      setFromShow(false);
      setToShow(false);
    }
  };
  const handleToButton = (e, index) => {
    e.preventDefault();
    if (fromShow === true || toShow === true) {
      setFromShow(false);
      setToShow(false);
    }
  };

  window.onclick = function (e) {
    if (fromShow && tempOpen === false) {
      setFromShow(false);
    } else if (toShow && tempOpen === false) {
      setToShow(false);
    }
  };

  const mouseClick = () => {
    setShowOptions(true);
    setAntDropdown("");
  };
  const isAddPatientPop = useSelector(
    (state) => state.Schedule.isAddPatientPop
  );
  const handleMouseLeave = (event) => {};

  const handleDropdownVisibleChange = (visible) => setShowOptions(visible);
  //-------------------------------------------Function End--------------------------------------------------------

  //-------------------------------------------UseEffect Start--------------------------------------------------------
  useEffect(() => {
    if (isAddPatientPop) {
      dispatch(SetPatientBool(false));
      OnSubmit("none");
    }
  }, [isAddPatientPop]);

  useEffect(() => {
    calculateTimeDifference();
  }, [inTime, outTime]);
  useEffect(() => {
    if (isStateUpdated) {
      saveRepeatAvail();
    }
  }, [isStateUpdated]);

  useEffect(() => {
    if (id != 0) {
      setproviderid(row.pc_aid);
      setappoint_office(row.pc_catid);
      setCategoryName(row.pc_catname);
      setComments(row.pc_hometext);
      setapptdate(moment(row.pc_eventDate).format(dtFormat));
      setApptEndDate(moment(row.pc_endDate).format(dtFormat));
      setrepeatselectnew(row.pc_select1);

      setrepeatselect2new(row.pc_select2);

      setRepeatSelectMonth(row.pc_repeatselectmonth);

      const week_day = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];

      const month = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      const day = new Date(moment(apptdate, dtFormat).format("YYYY-MM-DD"));

      let setDay1 = week_day[day.getDay()];

      let setMonth1 = month[day.getMonth()];

      const monthIndex = month.indexOf(setMonth1);

      const monthNumber = monthIndex !== -1 ? monthIndex + 1 : null;

      let setDyOfMont1 = day.getDate();

      const weekNum = Math.ceil(day.getDate() / 7);
      if (weekNum == 1) {
        setDyOfMont1 = "First";
      } else if (weekNum == 2) {
        setDyOfMont1 = "Second";
      } else if (weekNum == 3) {
        setDyOfMont1 = "Third";
      } else if (weekNum == 4) {
        setDyOfMont1 = "Fourth";
      } else {
        setDyOfMont1 = "Fifth";
      }

      // if (row.pc_repeatselectmonth !== "") {
      //   if (
      //     row.pc_repeatselectmonth === "1" ||
      //     row.pc_repeatselectmonth === 1
      //   ) {
      //     setSelectMonthAlp(`Monthly on day ${monthNumber}`);
      //   } else if (
      //     row.pc_repeatselectmonth === "2" ||
      //     row.pc_repeatselectmonth === 2
      //   ) {
      //     setSelectMonthAlp(`Monthly on the ${setDyOfMont1} ${setDay1}`);
      //   }
      // }

      setcustChk(parseInt(row.pc_on_after_never));

      setrepValue(row.pc_repeat_select);

      if (row.pc_recurrtype > "0") {
        setchecking1(true);

        setuntildate(row.pc_endDate);
        if (row.pc_recurrtype == "1" || row.pc_recurrtype == "2") {
          setrepeatselectnew(row.pc_recurrspec.event_repeat_freq);
          setrepeatselect2new(row.pc_recurrspec.event_repeat_freq_type);
        } else if (row.pc_recurrtype == 3) {
          let temp = row.pc_recurrspec.event_repeat_freq.split(",");
          var temp_a = [];
          for (let i = 0; i < temp.length; i++) {
            let text = Number(temp[i]);
            temp_a.push(text);
          }
          days_check();
          setweekday(temp_a);

          setdayswise_c(true);
        } else if (row.pc_recurrtype === "4") {
          let temp = row.pc_recurrspec.event_repeat_freq_by_day.split(",");
          var temp_a = [];
          for (let i = 0; i < temp.length; i++) {
            let text = Number(temp[i]);
            temp_a.push(text);
          }
          setWeek_and_every(true);
          setweekday(temp_a);
        } else if (row.pc_recurrtype === "5") {
          let temp = row.pc_recurrspec.event_repeat_freq_by_day.split(",");
          var temp_a = [];
          for (let i = 0; i < temp.length; i++) {
            let text = Number(temp[i]);
            temp_a.push(text);
          }
          setMonth_and_every(true);
          setweekday(temp_a);
        }
      } else {
        setchecking1(false);
      }

      setInTime(moment(row.pc_startTime, "h:mm A").format("h:mm A"));
      setOutTime(moment(row.pc_endTime, "h:mm A").format("h:mm A"));
      // let duration = row.pc_duration / 60;
    }
  }, [row]);

  useEffect(() => {
    datas_1();

    axiosInstance
      .get("/vozo/calendarsettingdata")
      .then((response) => {
        let decodingResponse = Decrypt_Value(response.data, "vozo");
        setWeeklyDisable(decodingResponse[1].gl_value);
      })
      .catch(() => {});

    axiosInstance
      .get("vozo/calendarsettingdata/main")
      .then((response) => {
        let temp_role_cal = Decrypt_Value(response.data, "vozo");
        const timeSlots1 = [];
        const timeSlots2 = [];
        let i = 0;

        for (
          let hour = Number(temp_role_cal.schedule_start);
          hour < Number(temp_role_cal.schedule_end);
          hour++
        ) {
          for (let minute = 0; minute < 60; minute += 15) {
            let ampm = hour < 12 ? "AM" : "PM";
            let hourFormatted = hour > 12 ? hour - 12 : hour;

            if (hour === 24 && minute === 0) {
              ampm = "AM";
              hourFormatted = 12;
            }

            if (hour === 0) {
              hourFormatted = 12;
            }

            const time = `${hourFormatted.toString().padStart(2, "0")}:${minute
              .toString()
              .padStart(2, "0")}`;
            timeSlots1.push({ id: ++i, time: time, meridiem: ampm });
          }
        }
        setFromTimeSlots(timeSlots1);
        let j = 0;

        let skipFirstMidnight = true;

        for (
          let hour = Number(temp_role_cal.schedule_start);
          hour <= Number(temp_role_cal.schedule_end);
          hour++
        ) {
          const endMinute =
            hour === Number(temp_role_cal.schedule_end) ? 15 : 60;
          for (let minute = 0; minute < endMinute; minute += 15) {
            let ampm = hour < 12 ? "AM" : "PM";
            let hourFormatted = hour > 12 ? hour - 12 : hour;

            if (hour === 24 && minute === 0) {
              ampm = "AM";
              hourFormatted = 12;
            }

            if (hour === 0) {
              hourFormatted = 12;
            }

            const time = `${hourFormatted.toString().padStart(2, "0")}:${minute
              .toString()
              .padStart(2, "0")}`;

            if (time === "12:00" && ampm === "AM" && skipFirstMidnight) {
              skipFirstMidnight = false;
              continue;
            }

            timeSlots2.push({ id: ++j, time: time, meridiem: ampm });
          }
        }

        setToTimeSlots(timeSlots2);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    if (id === 0) {
      const fetchData = async () => {
        const one = async () => {
          let value = String(office_1);
          let parts = value.split(",");
          if (parts.length > 1) {
            setoffice_1(parts[0]);
            setShouldCallDatas2(true);
          } else {
            setoffice_1(value);
            setShouldCallDatas2(true);
          }
        };
        await one();
      };

      fetchData();
    }

    if (id != 0) {
      setoffice_1(row.pc_facility);
      setShouldCallDatas2(true);
    }
  }, []);
  useEffect(() => {
    if (shouldCallDatas2 === true) {
      datas_2();
      setShouldCallDatas2(false);
    }
  }, [office_1, shouldCallDatas2]);

  useEffect(() => {
    const week_day = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    const month = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const day = new Date(moment(apptdate, dtFormat).format("YYYY-MM-DD"));

    setDay(week_day[day.getDay()]);

    setMonth(month[day.getMonth()]);

    setDyOfMont(day.getDate());

    const weekNum = Math.ceil(day.getDate() / 7);
    if (weekNum == 1) {
      setWkOfMont("First");
    } else if (weekNum == 2) {
      setWkOfMont("Second");
    } else if (weekNum == 3) {
      setWkOfMont("Third");
    } else if (weekNum == 4) {
      setWkOfMont("Fourth");
    } else {
      setWkOfMont("Fifth");
    }
    if (apptdate !== "") {
      var dateObj = new Date(apptdate);
      const formattedApptDate = moment(apptdate, dtFormat);
      const newDate = formattedApptDate.clone().add(1, "days");
    }
    // }
  }, [apptdate, show, repeatselectmonth]);

  //-------------------------------------------UseEffect End--------------------------------------------------------

  return (
    <div
      id="add-allergy pro_avl-inp_srch"
      className={from === "new_appointment" && "new_app_visible"}
    >
      <Form onSubmit={OnSubmit}>
        {from !== "new_appointment" && (
          <Modal.Header closeButton className="modal_prov_avail">
            {isLoggedIn ? (
              <>
                <Modal.Title className="title-popup-template tit_proavail_alt tit_proavail_mod_alt">
                  Edit Provider Availability
                </Modal.Title>
              </>
            ) : (
              <>
                <Modal.Title className="title-popup-template tit_proavail_alt tit_proavail_mod_alt">
                  Add Provider Availability
                </Modal.Title>
              </>
            )}
          </Modal.Header>
        )}

        <Modal.Body className="modal_body position_unset">
          {SpinnerVal ? (
            <SpinnerDiv className="add_prov_avail_spinn">
              <Spinner animation="border" variant="primary" />
            </SpinnerDiv>
          ) : (
            <>
              <Form.Row className="add_app_mod1 antd-select-height2">
                <Form.Group
                  as={Col}
                  className="template-office-top template_hover"
                  sm={6}
                >
                  <Form.Label className="template-office">Facility</Form.Label>
                  <Select
                    showSearch
                    placeholder="Select Facility"
                    className="form-control elect_table_bor drop_select_pad"
                    suffixIcon={<BsFillCaretDownFill />}
                    dropdownClassName={`custom-dropdown ${antDropdown}`}
                    onMouseLeave={handleMouseLeave}
                    onClick={mouseClick}
                    onDropdownVisibleChange={handleDropdownVisibleChange}
                    dropdownRender={(menu) => (
                      <div onMouseLeave={handleMouseLeave}>
                        {showOptions && (
                          <div
                            style={{ margin: 0, padding: 0 }}
                            onMouseEnter={mouseClick}
                          >
                            {menu}
                          </div>
                        )}
                      </div>
                    )}
                    style={
                      oer
                        ? { border: "1px solid red", borderRadius: "5px" }
                        : {}
                    }
                    onChange={(e, data) => OfficeChange(data)}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.title ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    value={
                      office_1 !== "" && office_1 !== null
                        ? office_1
                        : undefined
                    }
                  >
                    {office.map((i) => (
                      <Option
                        key={i.id}
                        value={i.value}
                        title={i.title}
                        className="adapp-brd-none"
                      >
                        {i.title}
                      </Option>
                    ))}
                  </Select>
                  {oer && <p className="errormessage">Select the Facility</p>}
                </Form.Group>
                <Form.Group
                  as={Col}
                  className="template-office-top template_hover"
                >
                  <Form.Label className="template-app-office">
                    Category<span style={{ color: "#E23D28" }}>*</span>
                  </Form.Label>
                  <Select
                    showSearch
                    placeholder="Select Category"
                    className={
                      cer
                        ? `error_border form-control elect_table_bor drop_select_pad to-hide-ant-label ${
                            sourceprofile.length === 0 ? "hide-label" : ""
                          }`
                        : `form-control elect_table_bor drop_select_pad to-hide-ant-label ${
                            sourceprofile.length === 0 ? "hide-label" : ""
                          }`
                    }
                    dropdownClassName={`custom-dropdown ${antDropdown}`}
                    onMouseLeave={handleMouseLeave}
                    onClick={mouseClick}
                    onDropdownVisibleChange={handleDropdownVisibleChange}
                    dropdownRender={(menu) => (
                      <div
                        onMouseLeave={handleMouseLeave}
                        onMouseEnter={mouseClick}
                      >
                        {showOptions && (
                          <div
                            style={{ margin: 0, padding: 0 }}
                            onMouseEnter={mouseClick}
                          >
                            {menu}
                          </div>
                        )}
                      </div>
                    )}
                    onChange={(e, data) => {
                      CategoryChange(data);
                    }}
                    optionFilterProp="children"
                    suffixIcon={<BsFillCaretDownFill />}
                    filterOption={(input, option) =>
                      (option?.title ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    value={app_off !== "" ? app_off : undefined}
                  >
                    {sourceprofile.map((i, ind) => (
                      <Option
                        key={ind}
                        value={i.value}
                        title={i.title}
                        disabled={i.disable === "yes" ? true : false}
                        className="adapp-brd-none"
                      >
                        {i.title}
                      </Option>
                    ))}
                  </Select>
                  {cer && (
                    <p className="errormessage position-absolute">
                      Select the Category
                    </p>
                  )}
                  {sourceprofile.length === 0 && id != 0 && (
                    <Spinner
                      animation="border"
                      size="sm"
                      variant="primary"
                      className="app-tele-spinner"
                    />
                  )}
                </Form.Group>
              </Form.Row>
              {categoryName === "Unavailable" && (
                <Form.Row className="add_app_mod3 antd-select-height2">
                  <Form.Group className="add_provavail_form_apptsts template_hover">
                    <Form.Label className="mbN">
                      Unavailable - Reason (Optional)
                    </Form.Label>
                    <Form.Label className="mbNS">
                      Enter the reason of your unavailability.
                    </Form.Label>
                    <textarea
                      className="prov_avail_textarea"
                      value={comments}
                      maxLength={20}
                      onChange={(e) => setComments(e.target.value)}
                    ></textarea>
                  </Form.Group>
                </Form.Row>
              )}

              <Form.Row className="add_app_mod2 antd-select-height2">
                <Form.Group as={Col} className="template_hover" sm={6}>
                  <Form.Label>
                    Provider<span style={{ color: "#E23D28" }}>*</span>
                  </Form.Label>
                  <Select
                    showSearch
                    placeholder="Select Provider"
                    className={
                      per
                        ? "error_border form-control elect_table_bor drop_select_pad"
                        : "form-control elect_table_bor drop_select_pad"
                    }
                    onChange={(e, data) => providerChange(e, data)}
                    suffixIcon={<BsFillCaretDownFill />}
                    dropdownClassName={`custom-dropdown ${antDropdown}`}
                    onMouseLeave={handleMouseLeave}
                    onClick={mouseClick}
                    onDropdownVisibleChange={handleDropdownVisibleChange}
                    dropdownRender={(menu) => (
                      <div
                        onMouseLeave={handleMouseLeave}
                        onMouseEnter={mouseClick}
                      >
                        {showOptions && (
                          <div
                            style={{ margin: 0, padding: 0 }}
                            onMouseEnter={mouseClick}
                          >
                            {menu}
                          </div>
                        )}
                      </div>
                    )}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.title ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    value={providerid !== "" ? providerid : undefined}
                  >
                    {sourceprovider.map((i) => (
                      <Option
                        key={i.id}
                        value={i.value}
                        title={i.title}
                        className="adapp-brd-none"
                      >
                        {i.title}
                      </Option>
                    ))}
                  </Select>
                  {per && (
                    <p className="errormessage position-absolute">
                      Select a Provider
                    </p>
                  )}
                </Form.Group>
              </Form.Row>

              <Form.Row className="for_display_wrap">
                <Form.Group as={Col} className="position_unset">
                  <Form.Label>Date</Form.Label>

                  <DatePicker
                    onChange={(date) => {
                      setapptdate(moment(date).format(dtFormat));
                      setApptEndDate(
                        moment(date).add(1, "days").format(dtFormat)
                      );
                    }}
                    className={
                      der
                        ? "product_Error provideravailpic"
                        : "provideravailpic"
                    }
                    placeholder="From-"
                    format={dtFormat}
                    value={apptdate ? moment(apptdate, dtFormat) : null}
                    allowClear={false}
                    disabledDate={(current) => {
                      // Disable dates before today
                      return current && current < moment().startOf("day");
                    }}
                  />
                  {der ? <p className="errormessage">Select the Date</p> : ""}
                </Form.Group>

                <Form.Group as={Col} className="position_unset">
                  <Form.Label>
                    From Time<span style={{ color: "#E23D28" }}>*</span>
                  </Form.Label>
                  <div className="position-relative">
                    <input
                      className="new_TimeSlot position-relative"
                      onClick={(e) => {
                        handleFromTime(e);
                      }}
                      onMouseLeave={() => {
                        setTempOpen(false);
                      }}
                      value={inTime}
                      onChange={(e) => {
                        setInTime(e.target.value);
                      }}
                      style={
                        ter
                          ? {
                              border: "1px solid red",
                              borderRadius: "5px",
                            }
                          : {}
                      }
                    />
                    <AccessTimeIcon className="position-absolute from_time_svg" />
                  </div>
                  {ter && (
                    <p className="errormessage position-absolute">
                      Select Valid Time
                    </p>
                  )}
                  <div className={fromShow ? "from_time_" : "fromArray_hide"}>
                    {frtimeSlots.map((item, index) => {
                      return (
                        <button
                          key={item.id}
                          value={item.time + " " + item.meridiem}
                          onClick={async (e) => {
                            setInTime(
                              moment(e.target.value, "h:mm A").format("h:mm A")
                            );

                            const selectedTime = moment(
                              e.target.value,
                              "h:mm A"
                            );
                            const endTime = moment(outTime, "h:mm A");

                            if (
                              !regexFirstNum.test(e.target.value) &&
                              selectedTime.isValid()
                            ) {
                              if (
                                outTime !== "" &&
                                outTime !== "12:00 AM" &&
                                selectedTime.isSameOrAfter(endTime)
                              ) {
                                setter(true);
                              } else {
                                setter(false);
                              }
                            }

                            handleFromButton(e);
                          }}
                          className="add-pro-avail-time-btn"
                        >
                          {item.time + " " + item.meridiem}
                        </button>
                      );
                    })}
                  </div>
                </Form.Group>
                <div className="flex-center m-t-px"> - </div>
                <Form.Group as={Col} className="position_unset">
                  <Form.Label>
                    To Time<span style={{ color: "#E23D28" }}>*</span>
                  </Form.Label>
                  <div className="position-relative">
                    <input
                      className="new_TimeSlot position-relative"
                      onClick={() => {
                        handleToTime();
                      }}
                      onChange={(e) => {
                        setOutTime(e.target.value);
                      }}
                      onMouseLeave={() => {
                        setTempOpen(false);
                      }}
                      value={outTime}
                      style={
                        ter1
                          ? {
                              border: "1px solid red",
                              borderRadius: "5px",
                            }
                          : {}
                      }
                    />
                    <AccessTimeIcon className="position-absolute to_time_Svg" />
                  </div>
                  {ter1 && (
                    <p className="errormessage position-absolute">
                      Select Valid Time
                    </p>
                  )}
                  <div className={toShow ? "from_time_" : "toArray_hide"}>
                    {totimeSlots.map((item, index) => {
                      return (
                        <button
                          key={index}
                          value={item.time + " " + item.meridiem}
                          onClick={async (e) => {
                            setOutTime(
                              moment(e.target.value, "h:mm A").format("h:mm A")
                            );

                            const selectedTime = moment(
                              e.target.value,
                              "h:mm A"
                            );

                            if (!regexFirstNum.test(e.target.value)) {
                              if (
                                moment(selectedTime).isBefore(
                                  moment(inTime, "h:mm A")
                                ) &&
                                e.target.value !== "12:00 AM" &&
                                e.target.value !== "00:00 AM"
                              ) {
                                setter(true);
                              } else {
                                setter(false);
                              }
                            }

                            handleToButton(e);
                          }}
                          className="add-pro-avail-time-btn"
                          disabled={moment(
                            item.time.trim() + " " + item.meridiem.trim(),
                            "h:mm A"
                          ).isSameOrBefore(moment(inTime, "h:mm A"))}
                        >
                          {item.time + " " + item.meridiem}
                        </button>
                      );
                    })}
                  </div>
                </Form.Group>

                <Form.Group as={Col}>
                  <Form.Label>Duration</Form.Label>
                  <input
                    className="new_TimeSlot duration_slot"
                    value={`${
                      inTime !== "" && outTime !== "" && timeDifference === 1
                        ? `${timeDifference} hr`
                        : inTime !== "" && outTime !== "" && timeDifference > 1
                        ? `${timeDifference} hrs`
                        : ""
                    } ${
                      inTime !== "" && outTime !== "" && minuteDifference > 0
                        ? `${minuteDifference} mins`
                        : ""
                    }`}
                    disabled={true}
                  ></input>
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} sm={4.5} className="position_unset m-b-0">
                  <Div
                    className={
                      repvalue === "Week"
                        ? "app_repeat_dropdownWidth app_repeat_dropdown"
                        : "app_repeat_dropdown"
                    }
                    margin="10px 0px 22px 0px"
                  >
                    <BsFillCaretDownFill
                      className={
                        repvalue === "Week"
                          ? "down_arrow_doesnt right_align_down_week"
                          : "down_arrow_doesnt right_align_down"
                      }
                    />
                    <DropdownButton
                      id="arrow-newAppt"
                      className={
                        repvalue === "Week"
                          ? " app-nav-dropdownWidth app_rel app-nav-dropdown"
                          : where === "top"
                          ? "app-nav-dropdown app-nav-dropdown_fromtop app_rel"
                          : "app-nav-dropdown app_rel"
                      }
                      title={repvalue ? repvalue : "Does not repeat"}
                      onSelect={handlerepSelect}
                      variant="light"
                    >
                      <Dropdownant.Item eventKey="Does not repeat">
                        Does not repeat
                      </Dropdownant.Item>
                      <Dropdownant.Item eventKey="Daily">
                        Daily
                      </Dropdownant.Item>
                      <Dropdownant.Item eventKey={"Week"}>
                        Week
                      </Dropdownant.Item>
                    </DropdownButton>
                  </Div>
                </Form.Group>
                {repvalue === "Week" && (
                  <Div margin="10px 4px 14px 0px">
                    <ToggleButtonGroup
                      type="checkbox"
                      value={weekday}
                      className="appt_repeats but-act"
                      onChange={WeekdayChange}
                      style={
                        selectdayError
                          ? { border: "1px solid red", borderRadius: "5px" }
                          : {}
                      }
                    >
                      <ToggleButton
                        disabled={
                          WeeklyDisable === "Show only weekdays" ||
                          WeeklyDisable === "Don't show Sundays"
                            ? true
                            : false
                        }
                        className={
                          WeeklyDisable === "Show only weekdays" ||
                          (WeeklyDisable === "Don't show Sundays" &&
                            "weekDisableBtn")
                        }
                        id="tbg-btn-1"
                        value={1}
                      >
                        S
                      </ToggleButton>
                      <ToggleButton id="tbg-btn-2" value={2}>
                        M
                      </ToggleButton>
                      <ToggleButton id="tbg-btn-3" value={3}>
                        T
                      </ToggleButton>
                      <ToggleButton id="tbg-btn-3" value={4}>
                        W
                      </ToggleButton>
                      <ToggleButton id="tbg-btn-3" value={5}>
                        T
                      </ToggleButton>
                      <ToggleButton id="tbg-btn-3" value={6}>
                        F
                      </ToggleButton>
                      <ToggleButton
                        disabled={
                          WeeklyDisable === "Show only weekdays" ? true : false
                        }
                        id="tbg-btn-3"
                        value={7}
                      >
                        S
                      </ToggleButton>
                    </ToggleButtonGroup>

                    {selectdayError && (
                      <p className="errormessage">
                        Please select atleast a day
                      </p>
                    )}
                  </Div>
                )}
                {repvalue !== "" &&
                  repvalue !== undefined &&
                  repvalue !== null &&
                  repvalue !== "Does not repeat" &&
                  repvalue !== "Does not repeat " && (
                    <Form.Group
                      as={Col}
                      className="position_unset m-t-10 m-b-0"
                    >
                      <Div dis="flex">
                        <Div mb="0px" className="provOptionsList">
                          Until:
                        </Div>

                        <DatePicker
                          disabledDate={(current) => {
                            const oneYearFromNow = moment(apptdate, dtFormat)
                              .add(1, "year")
                              .startOf("day");
                            return (
                              current.isBefore(
                                moment(apptdate, dtFormat),
                                "day"
                              ) || current.isAfter(oneYearFromNow, "day")
                            );
                          }}
                          onChange={(e) =>
                            setApptEndDate(moment(e).format(dtFormat))
                          }
                          format={dtFormat}
                          value={apptEndDate && moment(apptEndDate, dtFormat)}
                          allowClear={false}
                        />
                      </Div>

                      {untilErr ? (
                        <p className="errormessage">Select the Date</p>
                      ) : (
                        ""
                      )}
                    </Form.Group>
                  )}
              </Form.Row>
            </>
          )}
        </Modal.Body>
        {from !== "new_appointment" && (
          <Modal.Footer className="new_appointment">
            <div>
              <Button
                className="close_active close-btn"
                variant="light"
                onClick={clientShow}
              >
                Close
              </Button>
              <Button
                className="save_active save-btn m-l-20"
                type="submit"
                variant="primary"
                disabled={btnDisable}
              >
                Save
              </Button>
            </div>
          </Modal.Footer>
        )}
      </Form>

      <Modal
        className="alert-popup-message"
        show={ModalAlerShow}
        onHide={() => setModalAlerShow(false)}
        closeButton
      >
        <div className="alert msg alert-danger" role="alert">
          <BsExclamationCircle className="icons-bs" />
          {ModalMsg}
        </div>
      </Modal>

      <Modal
        className="alert-popup-message"
        show={ModalAlerShow1}
        onHide={() => setModalAlerShow1(false)}
        closeButton
      >
        <div className="alert msg alert-success" role="alert">
          <BsExclamationCircle className="icons-bs" />
          {ModalMsg}
        </div>
      </Modal>

      {repeatOpenPopup && (
        <Modal
          className="app-popup repeat-popup rept-modal popup_centre edit_conf_border"
          centered
          backdrop="static"
          keyboard={false}
          show={repeatOpenPopup}
        >
          <RepeatPopupBlock>
            <RepeatPopupHeader>Edit Confirmation</RepeatPopupHeader>
            <RepeatPopupBodyText>
              Please select & confirm the below option before take action
            </RepeatPopupBodyText>
            {AlertDeleteMsg !== "" && (
              <RepeatPopupBodyText color="rgb(226, 61, 40)">
                {AlertDeleteMsg}
              </RepeatPopupBodyText>
            )}
            <RepeatPopupBodyBtn>
              <RepeatBtn
                onClick={() => {
                  setrepeatbtn((repeatbtn) => ({
                    ...defaultRepeatBtn,
                    current: true,
                  }));
                  if (row.pc_catid === "4" && app_off === "5") {
                    setAlertDeleteMsg(
                      "Your Current Telehealth Appointments(Except Meet Completed Appointment's)  are Deleted Because You choose Unavailable from telehealth"
                    );
                  } else {
                    setAlertDeleteMsg("");
                  }
                }}
                active={repeatbtn.current}
              >
                Current
              </RepeatBtn>
              {futureBtnBlock ? (
                <Tooltip title="No Future Dates Available">
                  <RepeatBtn>Future</RepeatBtn>
                </Tooltip>
              ) : (
                <RepeatBtn
                  onClick={() => {
                    setrepeatbtn((repeatbtn) => ({
                      ...defaultRepeatBtn,
                      future: true,
                    }));
                    if (row.pc_catid === "4" && app_off === "5") {
                      setAlertDeleteMsg(
                        "Your Future Telehealth Appointments(Except Meet Completed Appointment's)  are Deleted Because You choose Unavailable from telehealth"
                      );
                    } else {
                      setAlertDeleteMsg("");
                    }
                  }}
                  active={repeatbtn.future}
                >
                  Future
                </RepeatBtn>
              )}
              <RepeatBtn
                onClick={() => {
                  setrepeatbtn((repeatbtn) => ({
                    ...defaultRepeatBtn,
                    all: true,
                  }));
                  if (row.pc_catid === "4" && app_off === "5") {
                    setAlertDeleteMsg(
                      "Your Future Telehealth Appointments(Except Meet Completed Appointment's)  are Deleted Because You choose Unavailable from telehealth"
                    );
                  } else {
                    setAlertDeleteMsg("");
                  }
                }}
                active={repeatbtn.all}
              >
                All
              </RepeatBtn>{" "}
            </RepeatPopupBodyBtn>
            <RepeatFooter>
              <RepeatBtn
                active={false}
                onClick={() => {
                  setrepeatbtn(defaultRepeatBtn);

                  // setrepeatbtn(defaultRepeatBtn);
                  setRepeatOpenPopup(false);
                }}
                className="close_active close_active2"
              >
                Cancel
              </RepeatBtn>
              <RepeatBtnConfirm confirm={true} onClick={saveRepeatAvail}>
                Confirm
              </RepeatBtnConfirm>
            </RepeatFooter>
          </RepeatPopupBlock>
        </Modal>
      )}
      {ConfirmOpen && (
        <Modal
          className="c_a_f_d_a app-popup repeat-popup rept-modal popup_centre edit_conf_border"
          centered
          backdrop="static"
          keyboard={false}
          show={ConfirmOpen}
        >
          <Div padding="15px">
            <Div margin="0px 0px 10px 0px">
              <BsExclamationCircle
                style={{ fontSize: "16px", color: "#F24822" }}
              />
              <Span>Can’t set Availability</Span>
            </Div>

            <Span>
              Changing your availability may result in the cancellation of
              appointments currently scheduled in those specific time slots. Are
              you sure you wish to change your availability?
            </Span>

            <Div textAlign="end" margin="10px 0px 0px 0px">
              <Button
                className="close_active close_save_btn_avail_check"
                variant="light"
                onClick={() => setConfirmOpen(false)}
              >
                Cancel
              </Button>
              <Button
                className="save_active m-l-20 close_save_btn_avail_check"
                type="submit"
                variant="primary"
                onClick={CheckOnSubmit}
              >
                Yes
              </Button>
            </Div>
          </Div>
        </Modal>
      )}
    </div>
  );
};

export default memo(AddAppTemplate);
