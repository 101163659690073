import { createSlice } from "@reduxjs/toolkit";

export const ScheduleSlice = createSlice({
  name: "Schedule",
  initialState: {
    isAddPatientPop: false,
    AuditLogData: [],
    PatientSearchData: [],
    UserSearchData: [],
    PatientSearchNoResult: "1",
    UserSearchNoResult: "1",
    ScheduleLoading: false,
    BillingFacility: "",
    PracticeDashboard: [],
    DelInvData: "",
    TotalCreditAmt: false,
    AddPayment: [],
    PatCreditPay: "",
    showComp: false,
    ChangeStatus: false,
    ChangeStatusAll: false,
    repeatCheck: false,
    repeatOrNot: false,
    id: "",
    InvoiceLoading: false,
    DashboardLoading: false,
    saveButton: {
      appointment: false,
      billing: false,
      vitals: false,
    },
    btnDis: false,
    SpinnerVal: true,
    repeats_checked: false,
    callFunction: {
      appointment: 1,
      billing: 1,
      vitals: 1,
    },
    changeFacility: false,
    modifyAppointmentData: [],
    modifyAppointment: false,
    NavData: "iconopen",
    SideNavData: "demographics",
    selectedEvent: [],
  },
  reducers: {
    GetPatientBool: (Data) => {
      return Data;
    },
    SetPatientBool: (state, action) => {
      state.isAddPatientPop = action.payload;
    },
    GetAuditLogData: (Data) => {
      return Data;
    },
    GetAuditLogDataLoading: (state) => {
      state.ScheduleLoading = true;
    },
    SetAuditLogData: (state, action) => {
      state.AuditLogData = action.payload;
      state.ScheduleLoading = false;
    },
    SetAuditLogTenData(state, action) {
      state.AuditLogData = [...state.AuditLogData, ...action.payload];
    },

    GetPatientSearchData: (Data) => {
      return Data;
    },
    SetPatientSearchData: (state, action) => {
      state.PatientSearchData = action.payload;
      state.ScheduleLoading = false;
    },
    SetPatientSearchNoResult: (state, action) => {
      state.PatientSearchNoResult = action.payload;
      state.PatientSearchData = [];
    },

    GetUserSearchData: (Data) => {
      return Data;
    },
    SetUserSearchData: (state, action) => {
      state.UserSearchData = action.payload;
      state.ScheduleLoading = false;
    },
    SetUserSearchNoResult: (state, action) => {
      state.UserSearchNoResult = action.payload;
      state.UserSearchData = [];
    },
    GetBillingFacility: (Data) => {
      return Data;
    },
    setBillingFacility: (state, action) => {
      state.BillingFacility = action.payload;
    },
    GetPracticeDashboard: (Data) => {
      return Data;
    },
    GetPracticeDashboardLoading: (state) => {
      state.DashboardLoading = true;
    },
    SetPracticeDashboard: (state, action) => {
      state.PracticeDashboard = action.payload;
      state.DashboardLoading = false;
    },
    SetPracticeTenDashboard(state, action) {
      state.PracticeDashboard = [...state.PracticeDashboard, ...action.payload];
    },
    GetDeleteInvoice: (state) => {
      state.InvoiceLoading = true;
    },
    SetDeleteInvoice: (state, action) => {
      state.DelInvData = action.payload;
      state.InvoiceLoading = false;
    },

    GetTotalCreditAmt: (state) => {
      state.InvoiceLoading = true;
    },
    SetTotalCreditAmt: (state, action) => {
      state.TotalCreditAmt = action.payload;
      state.InvoiceLoading = false;
    },

    GetAddPayment: (state) => {
      state.InvoiceLoading = true;
    },
    SetAddPayment: (state, action) => {
      state.AddPayment = action.payload;
      state.InvoiceLoading = false;
    },

    GetPatCreditPay: (state) => {
      state.InvoiceLoading = true;
    },
    SetPatCreditPay: (state, action) => {
      state.PatCreditPay = action.payload;
      state.InvoiceLoading = false;
    },

    SetCompOpen: (state, action) => {
      state.showComp = action.payload;
    },
    changeAppointmentStatus: (state, action) => {
      let data = action.payload;
      if (data.repeatOrNot === 0 || data.repeatOrNot === "0") {
        state.repeatOrNot = false;
      } else {
        state.repeatOrNot = true;
      }
      state.id = data.id;
      state.ChangeStatus = data.value;
    },
    changeAppointmentStatusAll: (state, action) => {
      let data = action.payload;
      state.ChangeStatusAll = data.value;
      state.repeatCheck = data.RepeatOrNot;
    },
    changeAppointmentValues: (state, action) => {
      let data = action.payload;
      if (data.name === "save button") {
        state.saveButton[data.value] = true;
      } else if (data.name === "disable") {
        state.btnDis = data.value;
      } else if (data.name === "spinner") {
        state.SpinnerVal = data.value;
      } else if (data.name === "repeats checked") {
        state.repeats_checked = data.value;
      } else if (data.name === "call function") {
        state.callFunction[data.value] = state.callFunction[data.value] + 1;
      } else if (data.name === "reset") {
        state.callFunction = {
          appointment: 1,
          billing: 1,
          vitals: 1,
        };
        state.saveButton = {
          appointment: false,
          billing: false,
          vitals: false,
        };
      } else if (data.name === "reset call") {
        state.callFunction = {
          appointment: 1,
          billing: 1,
          vitals: 1,
        };
      }
    },
    changeFacilitySelect: (state, action) => {
      state.changeFacility = action.payload;
    },
    openToModify: (state, action) => {
      const { data, value } = action.payload;
      state.modifyAppointment = value;
      state.modifyAppointmentData = data["row"];
    },
    GetNav: (state, action) => {
      state.NavData = action.payload;
    },
    GetSideNav: (state, action) => {
      state.SideNavData = action.payload;
    },
    changeSelectedEvent: (state, action) => {
      state.selectedEvent = action.payload;
    },
  },
});

export const {
  GetPatientBool,
  SetPatientBool,
  GetAuditLogData,
  SetAuditLogData,
  GetAuditLogDataLoading,
  GetPatientSearchData,
  SetPatientSearchData,
  GetUserSearchData,
  SetUserSearchData,
  SetAuditLogTenData,
  SetPatientSearchNoResult,
  SetUserSearchNoResult,
  GetBillingFacility,
  setBillingFacility,
  GetPracticeDashboard,
  GetPracticeDashboardLoading,
  SetPracticeDashboard,
  SetPracticeTenDashboard,
  SetDeleteInvoice,
  GetDeleteInvoice,
  GetTotalCreditAmt,
  SetTotalCreditAmt,
  GetAddPayment,
  SetAddPayment,
  SetCompOpen,
  GetPatCreditPay,
  SetPatCreditPay,
  changeAppointmentValues,
  changeAppointmentStatus,
  changeAppointmentStatusAll,
  changeFacilitySelect,
  openToModify,
  GetNav,
  GetSideNav,
  changeSelectedEvent,
} = ScheduleSlice.actions;

export default ScheduleSlice.reducer;
